import React from 'react';
import { Container } from './Error.styles.js';
import { useHistory } from 'react-router-dom';

const Error = () => {
    const history = useHistory();

    return (
        <>
            <Container>
                <div className='animacao'>
                    <div className='extrator'></div>
                    <div className='topo'></div>
                    <div className='cabo3'></div>
                    <div className='base'>
                        <div className='cabo1'></div>
                        <div className='cabo2'></div>
                        <div className='contrapeso'></div>
                    </div>
                </div>
                <div>
                    <p className='texto'>Ops! Ocorreu um erro.</p>
                    <button className='botao' onClick={() => history.push('/dashboard')}>Clique Para Retornar</button>
                </div>
            </Container>
        </>
    )
}

export default Error; 
